@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');
* {
    font-family: 'Nunito', sans-serif;
}

.react-tiny-popover-container {
    border-radius: 4px;
    border: 1px #d8d8d8 solid;
    z-index: 9999;
}
